'use client'
import { Tabs } from "antd/lib";

import { useEffect, useState } from "react";
import './index.scss'
import { useGlobalState } from "@/base/state/slice";
export const BUSINESS_ID = 'Business-Id';

export const BusinessSwitch = () => {
  
  const sessionBusinessId = sessionStorage.getItem(BUSINESS_ID);
  const { update: setBusinessId, state: businessId } = useGlobalState<string>(`app.businessId`);
  // const [businessId, setBusinessId] = useState(sessionBusinessId || '1')
  const onChange = (key: string) => {
    setTimeout(() => {
      window.location.href = '/order';
      setBusinessId(key);
      sessionStorage.setItem(BUSINESS_ID, key);
    }, 350); //order有一个300ms的onblur事件，所以这里要延迟350ms
    
  }
  useEffect(() => {
    setBusinessId(sessionBusinessId)
  }, [sessionBusinessId])
  const items = [{
    label: `Personal Rental`,
    key: '1',
  }, {
    label: `Short Term PHV Rental`,
    key: '2',
  }]
  return (
    <div className="business-switch">
      <Tabs defaultActiveKey={businessId || sessionBusinessId || '1'} items={items} onTabClick={onChange} />
    </div>
  )
}