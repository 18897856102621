"use client";

import { useGlobalState } from "@/base/state/slice";
import apisTree from "@/base/state/tree/api.json";
import { camelCase, isEmpty, upperFirst } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import useApplication from "../business-unit";
import { useAuthorizedStatefulApi } from "../stateful-api";
import {
  AccessData,
  AccessHook,
  AccessHookOptions,
  AccessibleRole,
} from "./index.type";

export const useAccess = (props?: AccessHookOptions): AccessHook => {
  const { moduleName } = props || {};
  const { businessUnit } = useApplication();

  const isKinto = businessUnit === "kinto";

  const { state: roleId } = useGlobalState("auth.roleId");
  const { state: access, update: updateAccess } =
    useGlobalState<AccessData[]>("auth.access");

  const [accessibleRole, setAccessibleRole] = useState<AccessibleRole>();

  const { data: { data } = {} as any, isLoading: isLoadingAccess } =
    useAuthorizedStatefulApi<any, any, any>(
      `resourceMethodPermission.getMany`,
      {
        skip: !roleId || isKinto,
      }
    );

  const accessData: AccessData[] = useMemo(() => {
    const resourceMethods = data
      ?.map((perm: any) => perm?.resource_methods)
      ?.flat();
    return Object.keys(apisTree)?.reduce((prev, key) => {
      const apiDataObj = (apisTree as any)?.[key];

      let accessibleRole: AccessibleRole = {};

      Object.keys(apiDataObj)?.forEach((apiActionKey) => {
        const { name, url } = (apiDataObj as any)?.[apiActionKey] || {};

        let [module, action] = url?.split("/") || [];
        action = action === "getlist" ? "GetList" : action;
        const apiName =
          name ||
          `${upperFirst(camelCase(action))}${upperFirst(camelCase(module))}`;

        if (isEmpty(apiName)) return;

        const hasPerm =
          isKinto ||
          !!resourceMethods?.find(({ name }: any) => name === apiName);

        accessibleRole = {
          ...accessibleRole,
          [`can${upperFirst(camelCase(apiActionKey))}`]: hasPerm,
        };
      });
      return prev?.concat([{ module: key, accessibleRole }]);
    }, [] as AccessData[]);
  }, [data, isKinto]);

  const getAccessibleRoleByModule = useCallback(
    (moduleName: string) =>
      accessData.find(({ module }) => module === camelCase(moduleName))
        ?.accessibleRole,
    [accessData]
  );

  useEffect(() => {
    updateAccess(accessData);
  }, [accessData, updateAccess]);

  useEffect(() => {
    if (!moduleName) return;
    const accessibleRole = getAccessibleRoleByModule(moduleName);
    setAccessibleRole(accessibleRole);
  }, [getAccessibleRoleByModule, moduleName]);
  return {
    getAccessibleRoleByModule,
    access,
    isLoadingAccess,
    accessibleRole,
    originalAccess: data,
  };
};
