"use client";
import { ACCESS_TOKEN } from "@/base/constant/auth";
import { useShowContext } from "@/base/crud/next/hoc/show/context";
import { useUpdateContext } from "@/base/crud/next/hoc/update/context";
import {
  getStatefulApiHeaders,
  setStatefulApiHeaders,
  useStatefulApi,
} from "@/base/state/api";
import { GlobalApiHook, GlobalApiHookOptions } from "@/base/state/api.type";
import { useAuth } from "../auth";

export const useAuthorizedStatefulApi = <
  B extends any,
  D extends any,
  E extends any
>(
  path: string,
  options?: GlobalApiHookOptions<B, D>,
  crudType?: string
): GlobalApiHook<B, D, E> => {
  const { handleError } = useAuth();
  
  const headerAuthKey = "authorization";
  const headers = getStatefulApiHeaders();
  if (!headers?.[headerAuthKey]) {
    if (typeof localStorage !== "undefined") {
      const token = localStorage.getItem(ACCESS_TOKEN);
      if (token) {
        setStatefulApiHeaders({
          ...headers,
          [headerAuthKey]: `Bearer ${token}`,
        });
      }
    }
  }

  const res = useStatefulApi<B, D, E>(path, options, crudType);
  const { error }: any = res || {};
  handleError(error);
  return res;
};

export const useUnathorizedStatefulApi = <
  B extends any,
  D extends any,
  E extends any
>(
  path: string,
  options?: GlobalApiHookOptions<B, D>
): GlobalApiHook<B, D, E> => {
  return useStatefulApi<B, D, E>(path, options);
};
