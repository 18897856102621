"use client";
import { setStatefulApiHeaders } from "@/base/state/api";
import { useGlobalState } from "@/base/state/slice";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";
import { isEmpty, isNil } from "lodash";
import { redirect, usePathname } from "next/navigation";
import { useCallback, useEffect } from "react";
import { ACCESS_TOKEN } from "../../constant/auth";
import { AuthHookOptions, UserRole } from "./index.type";
import paths from "./path/index.json";
import { notification } from "antd";

export const useAuth = (options?: AuthHookOptions) => {
  const { state: isAuthenticated, update: setAuthenticated } =
    useGlobalState("auth.authenticated");
  const { state: role, update: setUserRole } =
    useGlobalState<UserRole>("auth.role");
  const pathname = usePathname();

  const redirectToDefaultPage = useCallback(() => {
    const path = Object.keys(paths.defaultUnauthorizedPath || {})[0];
    if (path && pathname !== path) {
      redirect(path);
    }
  }, [pathname]);

  const redirectToNotFoundPage = useCallback(() => {
    const path = Object.keys(paths.defaultNotFoundPath || {})[0];
    if (path && pathname !== path) {
      redirect(path);
    }
  }, [pathname]);

  const isPathIncluded = useCallback(
    (paths: string[]) => {
      if (paths.includes(pathname)) return true;

      const pathnameParts = pathname.split("/");
      const wildcardPaths = paths.filter((path) => path.includes("*"));

      if (!wildcardPaths?.length) return false;

      return wildcardPaths.some((path) => {
        const parts = path.split("/");
        parts.forEach((part, i) => {
          if (part !== "*") return;
          parts[i] = pathnameParts[i] ?? "_";
        });
        return pathname.includes(parts.join("/"));
      });
    },
    [pathname]
  );

  const logout = useCallback(() => {
    localStorage.removeItem(ACCESS_TOKEN);
    setStatefulApiHeaders({});
  }, []);

  const handleError = useCallback((err: any) => {
    if (![16].includes(err?.data?.code) && ![401].includes(err?.status)) {
      return;
    }

    if ([16].includes(err?.data?.code)) {
      notification.error({
        message: "Something went wrong!",
        description: err?.data?.message || 'Token is expired',
        onClose: () => {
          notification.destroy();
          logout();
          redirect("/login");
        }
      })
    } else {
      logout();
      redirect("/login");
    }
  }, [logout])

  const getTokenPayload = useCallback(() => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const valid = !isNil(token) && !isEmpty(token);
    if (!valid) return null;
    return jwtDecode<any>(token);
  }, []);

  useEffect(() => {
    if (typeof localStorage !== "undefined") {
      const token = localStorage.getItem(ACCESS_TOKEN);
      let valid = !isNil(token) && !isEmpty(token);
      if (valid && token) {
        const { exp, role } = jwtDecode<any>(token);
        if (exp > 0 && dayjs.unix(exp as number).isBefore(new Date())) {
          valid = false;
        }

        setUserRole(role?.slug);
      }

      setAuthenticated(valid);

      if (!options?.redirectIfUnauthorized) return;

      const allAuthorizedPaths = Object.keys(paths.authorizedPaths || {});
      const allUnauthorizedPaths = Object.keys(paths.unauthorizedPaths || {});

      if (valid) {
        if (
          !isPathIncluded(allAuthorizedPaths) &&
          !isPathIncluded(allUnauthorizedPaths)
        ) {
          redirectToNotFoundPage();
        }
        return;
      }

      if (!isPathIncluded(allUnauthorizedPaths)) {
        redirectToDefaultPage();
      }
    }
  }, [
    pathname,
    setAuthenticated,
    options?.redirectIfUnauthorized,
    isPathIncluded,
    redirectToDefaultPage,
    redirectToNotFoundPage,
    setUserRole,
  ]);

  return {
    logout,
    getTokenPayload,
    isAuthenticated,
    role,
    handleError
  };
};
