import { BusinessUnitHook, BusinessUnitHookOptions } from "./type";
import buConfig from "./../../../../business-unit.json";

const useApplication = (
  options?: BusinessUnitHookOptions
): BusinessUnitHook => {
  const { name: businessUnit, applicationName } = buConfig;
  const { logoColor } = options || {};
  const logoUrl = `/${businessUnit}-logo${
    logoColor ? `-${logoColor}` : ""
  }.png`;
  const faviconUrl = `/${businessUnit}-favicon.ico`;

  return { businessUnit, applicationName, logoUrl, faviconUrl };
};

export default useApplication;
