"use client";
import { useAccess } from "@/base/hook/access";
import { useAuth } from "@/base/hook/auth";
import useApplication from "@/base/hook/business-unit";
import useGetMe from "@/base/hook/get-me";
import { useGlobalState } from "@/base/state/slice";
import { LogoutOutlined, MenuOutlined, ProfileOutlined } from "@ant-design/icons";
import { ProLayout } from "@ant-design/pro-components";
import { Divider, Drawer, Dropdown, Flex, Menu } from "antd";
import { useMediaQuery } from "react-responsive";
import { get } from "lodash";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useEffect, useMemo, useRef, useState } from "react";
import { getMainMenu } from "./_customMenu";
import "./index.scss";
import { BusinessSwitch } from "./BusinessSwitch";
import { MenuItemType } from "./_customMenu.type";
import { getRuntimeEnv } from "@/base/hoc/runtime-env";
const md5 = require("md5");

const Layout = ({ children }: any) => {
  const prod = getRuntimeEnv("NEXT_PUBLIC_PRO");
  const actionRef = useRef<{
    reload: () => void;
  }>();

  const router = useRouter();
  const { logoUrl, applicationName, businessUnit } = useApplication();
  const { logout } = useAuth();
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [menuOpen, setMenuOpen] = useState(false)

  const { originalAccess } = useAccess();
  const { role } = useAuth();
  const { data: userData, isFetching } = useGetMe();

  const { update: setUserRoleId } = useGlobalState("auth.roleId");

  const routes = useMemo(
    () => getMainMenu({ businessUnit, originalAccess, role }),
    [businessUnit, originalAccess, role]
  );
  const { name, avatarUrl, roleId } = useMemo(() => {
    const data: any = get(userData, "data");
    const name = get(data, "first_name", "") + " " + get(data, "last_name", "");
    const email = get(data, "user_account.email");
    const roleId = get(data, "role.id");

    return {
      name: name?.trim(),
      avatarUrl:
        data?.imageUrl ??
        `https://www.gravatar.com/avatar/${md5(email ?? "")}?size=150`,
      roleId,
    };
  }, [userData]);

  const onClick = () => {
    logout();
    router.push("/login");
  };

  useEffect(() => {
    setUserRoleId(roleId);
  }, [roleId, setUserRoleId]);
  console.log(prod);
  const changeRoutesToMenu = (routes: MenuItemType[]): any[] => {
    return routes.map((i, index) => {
      return {
        title: i.name, icon: i.icon, label: i.name, key: i.name || index,
        onClick: !i.routes ? () => {
          router.push(i.path || '/')
        }: undefined,
        children: i.routes ? changeRoutesToMenu(i.routes) : undefined
      }
    })
  }
  return (
    <ProLayout
      style={{ height: "99vh" }}
      token={{
        header: {
          colorBgHeader: "white",
        },
        sider: {
          paddingBlockLayoutMenu: 20,
          colorMenuBackground: "white",
          colorTextMenu: "#171725",
          colorTextMenuSelected: "#1677FF",
          colorBgMenuItemSelected: "#E6F4FF",
          colorBgMenuItemHover: "",
          colorTextMenuItemHover: "",
        },
      }}
      breakpoint="md"
      contentStyle={{ overflow: "scroll" }}
      layout={isTablet ? "top" : 'mix'}
      logo={logoUrl}
      title={applicationName}
      headerTitleRender={
        (logo, title) => (<>
          {(isTablet && !isMobile) ? <>{logo}<MenuOutlined style={{ fontSize: 18, marginInlineEnd: 16, cursor: "pointer", color: '#1677ff' }} onClick={() => setMenuOpen(true)} /></> : ''}
          {logo}
          {(!isTablet) ? title : ''}{prod}
        </>)
      }
      headerContentRender={() => isMobile ? false : <BusinessSwitch />}
      // collapsed={false}
      // onCollapse={setCollapsed}
      collapsedButtonRender={false}
      actionRef={actionRef}
      route={{ routes }}
      menuItemRender={(props: any, dom) => {
        return (
          <Link href={props.path}>
            <Flex align="center">
              <>
                {props.icon}
                <div style={{ marginLeft: "8px" }}>{props.name}</div>
              </>
            </Flex>
          </Link>
        );
      }}
      menuHeaderRender={
        !isTablet ? false : 
         (logo) => (<>
          {!isMobile ? 
          <>
            <MenuOutlined style={{ fontSize: 18, marginInlineEnd: 16, cursor: "pointer", color: '#1677ff'  }} onClick={() => setMenuOpen(true)} />
            <span className="header-img">{logo}</span>
          </>
         :<><span id='img'>{logo}</span> <BusinessSwitch /></>}
        </>)
      }
      subMenuItemRender={(props: any, dom) => {
        return (<>{props.path === '/admin' ? <div className="divider-dom" /> : ''}{dom}</>)
      }}
      avatarProps={{
        src: avatarUrl,
        size: "small",
        title: name,
        render: (_, dom) => {
          return (
            <Dropdown
              menu={{
                items: [
                  {
                    key: "me",
                    icon: <ProfileOutlined />,
                    label: <Link href={"/profile"}>My Profile</Link>,
                  },
                  {
                    key: "logout",
                    icon: <LogoutOutlined />,
                    label: <a onClick={onClick}>Logout</a>,
                  },
                ],
              }}
            >
              {dom}
            </Dropdown>
          );
        },
      }}
    >
      <Drawer styles={{body: {padding:0}}} width={300} placement="left" onClose={() => setMenuOpen(false)} open={menuOpen} closeIcon={false}>
        <div className="ant-layout-sider-children" style={{paddingInline: 12, marginLeft: 8}}><BusinessSwitch /></div>
        <Menu mode="inline" items={changeRoutesToMenu(routes)} />
      </Drawer>
      {isFetching === false && children}
    </ProLayout>
  );
};

export default Layout;
