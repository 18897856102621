import {
  AuditOutlined,
  CarOutlined,
  ClockCircleOutlined,
  ClusterOutlined,
  DollarCircleOutlined,
  IdcardOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { isArray, startCase, toLower } from "lodash";
import {
  AccessType,
  AccessibleType,
  MenuItemType,
  ModuleResourceName,
  ModuleResourceType
} from "./_customMenu.type";

//moduleName必须resource_method_permission/me（proto）的name一致
const MODULE_RESOURCE_NAME: ModuleResourceName = {
  ORDERS: {
    moduleName: "order",
    path: "/order",
    name: "Orders",
    icon: <ShoppingCartOutlined style={{ fontSize: "18px" }} />,
  },
  DRIVERS: {
    moduleName: "driver",
    path: "/driver",
    name: "Drivers",
    icon: <IdcardOutlined style={{ fontSize: "18px" }} />,
  },
  OPERATION_AVAILABILITY: {
    path: "/operation-availability",
    name: "Operation Availability",
    icon: <ClockCircleOutlined style={{ fontSize: "18px" }} />,
    routes: [
      {
        moduleName: "blackout_date",
        path: "/blackout-date",
        name: "Blackout Date",
      },
      {
        moduleName: "opening_hour",
        path: "/opening-hours",
        name: "Opening Hours",
      },
      {
        moduleName: "location",
        path: "/locations",
        name: "Location",
      },
    ],
  },
  CAR_CATALOG: {
    path: "/catalogue",
    name: "Car Catalog",
    icon: <CarOutlined style={{ fontSize: "18px" }} />,
    routes: [
      {
        moduleName: "item",
        path: "/item",
        name: "Car Model Tiers",
        accessible: {
          businessUnit: "kinto",
        },
      },
      {
        moduleName: "category",
        path: "/model-tier",
        name: "Car Model Tiers",
        accessible: {
          businessUnit: "lylo",
        },
      },
      {
        moduleName: "vehicle",
        path: "/vehicle",
        name: "Car Models",
        accessible: {
          businessUnit: "lylo",
        },
      },
      {
        moduleName: "add_on",
        path: "/add-on",
        name: "Add-On",
      },
      {
        moduleName: "sold_out_rule",
        path: "/vehicle-stock",
        name: "Car Stock",
      },
    ],
  },
  PROMO: {
    moduleName: "voucher",
    path: "/voucher",
    name: "Promo",
    icon: <TagOutlined style={{ fontSize: "18px" }} />,
  },
  PRICING_ENGINE: {
    moduleName: "pricing_engine",
    apiGetListName: "GetListPriceEngineRule",
    path: "/priceEngine",
    name: "Special Pricing",
    icon: <ClusterOutlined style={{ fontSize: "18px" }} />,
  },
  CONTRACT: {
    moduleName: "e_contract",
    path: "/contract",
    name: "Contract",
    accessible: {
      businessUnit: "lylo",
    },
    icon: <AuditOutlined style={{ fontSize: "18px" }} />,
  },
  PAYMENTS: {
    moduleName: "payment",
    path: "/payment",
    name: "Payments",
    icon: <DollarCircleOutlined style={{ fontSize: "18px" }} />,
  },
  ADMIN: {
    path: "/admin",
    name: "Admin",
    icon: <SettingOutlined style={{ fontSize: "18px" }} />,
    routes: [
      {
        moduleName: "admin_user",
        path: "/user",
        name: "Users",
      },
      {
        moduleName: "resource_method",
        path: "/access-control",
        name: "Access",
        accessible: {
          businessUnit: "lylo",
          roles: ["superadmin"],
        },
      },
    ],
  },
} as const;

export const getMainMenu = ({
  businessUnit,
  originalAccess,
  role,
}: {
  businessUnit: "kinto" | "lylo" | string;
  originalAccess: any[];
  role: string;
}): MenuItemType[] => {
  // if have perms and does not have accessible => true
  // if have accessible => must to validate accessible
  const isAccessible = (accessible: AccessibleType | undefined): boolean => {
    if (!accessible) return true;
    if (accessible?.businessUnit !== businessUnit) return false;
    if (!accessible?.roles) return true;
    return accessible?.roles?.includes(role);
  };

  const hasPermission = (moduleName: string | undefined): boolean => {
    if (businessUnit === "kinto") return true;
    if (!moduleName) return false;
    const methodName =
      Object.values(MODULE_RESOURCE_NAME)?.find(
        (resource) => resource?.moduleName === moduleName
      )?.apiGetListName ||
      `GetList${startCase(toLower(moduleName)).replaceAll(" ", "")}`;
    return !!originalAccess?.some(
      (access: any) =>
        access?.name === moduleName &&
        access.resource_methods?.some(
          (method: any) => method?.name === methodName
        )
    );
  };

  const traverseMenu = (moduleResource: ModuleResourceType): MenuItemType[] => {
    if (!moduleResource) return [];
    const { moduleName, path, name, routes, accessible, icon } =
      moduleResource || {};

    if (isArray(routes)) {
      const filteredRoutes = routes.reduce(
        (prev: MenuItemType[], route: MenuItemType & AccessType) => {
          const {
            moduleName: routeModuleName,
            path: routePath,
            name: routeName,
            accessible: routeAccessible,
          } = route;
          if (hasPermission(routeModuleName) && isAccessible(routeAccessible)) {
            return prev.concat({ path: routePath, name: routeName });
          }
          return prev;
        },
        []
      );

      if (filteredRoutes.length > 0) {
        return [{ path, name, icon, routes: filteredRoutes }];
      }
      return [];
    }

    if (hasPermission(moduleName) && isAccessible(accessible)) {
      return [{ path, name, icon }];
    }
    return [];
  };

  const menuTrees: MenuItemType[] = Object.values(MODULE_RESOURCE_NAME).reduce(
    (prev: MenuItemType[], moduleResource: ModuleResourceType) => {
      const menuItems = traverseMenu(moduleResource);
      return prev.concat(menuItems);
    },
    []
  );

  return menuTrees;
};
